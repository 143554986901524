import React, { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import LoginForm from './components/login/login';
import SelectParts from './components/orders/shirtconfig/SelectParts';

import OrdersMan from './components/orders/orderman/ordersMan';
import AuthProvider, { useAuth } from "./hooks/AuthProvider";
import PrivateRoute from "./router/route";
import { Navigate } from 'react-router-dom';

import './App.css';

import MainNavbar from './components/navbar/MainNavbar';
import TailorShopReg from './components/tailorshop/TailorShopReg';
import BodyMeasurement from './components/orders/measurements/BodyMeasurement';
import ClientProfile from './components/client/clientprofile/ClientProfile';
import OrderSteps from './components/orders/neworder/OrderSteps';
import FabricSelect from './components/orders/fabricselect/FabricSelect';
import UserProfile from './components/profile/userprofile';
import Forgotpassword from './components/login/ForgotPassword';
import ResetPassword from './components/login/ResetPassword';
import UsersAndRoles from './admincomps/users/UsersAndRoles';
import SyslogView from './admincomps/syslog/syslogview';
import CheckOrders from './factory/orders/checkorders/checkorder';
import GroupAnRoles from './admincomps/grouproles/GroupAndRoles';
import ExportToPdf from './factory/exporttools/ExportToPdf';
import OrderDetails from './factory/orders/orderdetail/orderDetail';
import SendBatch from './factory/orders/sendbatch/sendbatch';
import RoleAndPermissions from './admincomps/rolespermissions/RoleAndPermissions';
import TailorShopList from './factory/tailorshops/tailorshopman/TailorShopList';
import TailorShopMan from './factory/tailorshops/tailorshopman/tailorshopman';
import MainReport from './factory/reports/mainreport/MainReport';
import DashBoard from './factory/dashboard/maindashboard/DashBoard';
import AddModUser from './admincomps/users/AddModUser';
import AddModGroup from './admincomps/grouproles/AddModGroup';
import AddModRole from './admincomps/grouproles/AddModRole';
import FabricMan from './factory/fabrics/FabricsMan';
import PatternSearch from './factory/patternsearch/PatternSearch';
import RemoveOrders from './factory/orders/removeorders/RemoveOrder';
import BatchOrders from './factory/batchoders/BatchOrders';

const Home = () => {

  const auth = useAuth();
  // if (auth.user === null || auth.user === undefined) {

  // }

  return (


    <div>
      {auth.user && <DashBoard />}

      {!auth.user && <>
        <div className="container-md m-auto">
          <div className="wrap text-center1 my-auto home" style={{ height: "600px" }}>

          </div>
        </div>
      </>}

    </div>
  );
};

const About = () => {
  return (

    <div className="container p-3 p-lg-4 p-xl-5 home" style={{ text: "#ffffff" }}>
      <p className="text-uppercase title mb-3" style={{ color: "white" }}><strong >YEN BIEN COMPANY LIMITED</strong></p>
      <div className="add mb-3 d-flex item">
        <span className="icon d-none"><i className="fa fa-map-marker-alt "></i></span>
        <div>
          <strong className="d-block" style={{ color: "white" }}>Address</strong>
          <span style={{ color: "white" }}>Nha Vuon Lo 20, Me Tri Ha Street, Du An Me Tri Ha, Me Tri Ward, Nam Tu Liem District, Hanoi, Vietnam</span>
        </div>
      </div>
      <div className="phone mb-3 item d-flex">
        <span className="icon d-none"><i className="fa  fa-mobile-alt "></i></span>
        <div>
          <strong className="d-block" style={{ color: "white" }}>Phone number</strong>
          <span>
            <a href="tel:+84 903444019" style={{ color: "white" }}>+84 903444019</a>
            - <a href="tel:+84 866061497 (WhatsApp/ Zalo)" style={{ color: "white" }}>+84 866061497 (WhatsApp/ Zalo)</a>                                </span>
        </div>
      </div>
      <div className="email mb-0 item d-flex">
        <span className="icon d-none"><i className="far fa-envelope "></i></span>
        <div>
          <strong className="d-block" style={{ color: "white" }}>Email</strong>
          <span><a href="mailto:contact@yenbien.com" style={{ color: "white" }}>contact@yenbien.com</a></span>
        </div>
      </div>
    </div>

  );
};


const MainApp = () => {
  //const isAuthenticated = false;

  return (
    <Router>
      <Fragment>
        <AuthProvider>
          <MainNavbar />
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/" element={<Home />} />
            <Route path="/renewpass" element={<Forgotpassword />} />
            <Route path="/resetpass" element={<ResetPassword />} />
            <Route element={<PrivateRoute />}>
              <Route exact path='/selectparts' element={<SelectParts />} />
              <Route exact path='/ordersman' element={<OrdersMan />} />
              <Route exact path='/tailorshopreg' element={<TailorShopReg />} />
              <Route exact path='/bodymeasure' element={<BodyMeasurement />} />
              <Route exact path='/clientprofile' element={<ClientProfile />} />
              {/* <Route exact path='/neworder' element={<OrderSteps />} />
              <Route exact path='/fabrics' element={<FabricSelect />} /> */}
              <Route exact path='/profile' element={<UserProfile />} />

              <Route exact path='/reports' element={<MainReport />} />
              <Route exact path='/dashboard' element={<DashBoard />} />



              <Route exact path='/fabricman' element={<FabricMan />} />

              <Route exact path='/patternsearch' element={<PatternSearch />} />
              <Route exact path='/batchorders' element={<BatchOrders />} />


              <Route exact path='/tailorshop-man' element={<TailorShopMan />} />

              <Route exact path='/adduser' element={<AddModUser />} />
              <Route exact path='/addgroup' element={<AddModGroup />} />
              <Route exact path='/addrole' element={<AddModRole />} />

              <Route exact path='/users-roles' element={<UsersAndRoles />} />
              <Route exact path='/group-roles' element={<GroupAnRoles />} />
              <Route exact path='/role-permissions' element={<RoleAndPermissions />} />

              <Route exact path='/syslog' element={<SyslogView />} />
              <Route exact path='/checkorder' element={<CheckOrders />} />
              <Route exact path='/removeorder' element={<RemoveOrders />} />
              <Route exact path='/sendbatch' element={<SendBatch />} />

              <Route exact path='/export2pdf' element={<ExportToPdf />} />


              <Route exact path='/orderdetails' element={<OrderDetails />} />


            </Route>
          </Routes>
        </AuthProvider>
      </Fragment>

    </Router>
  );
};

function App() {
  return (
    <div>

      <MainApp />
    </div>
  );
}

export default App;
